module.exports = [
    {
        name:"headerAbout",
        url:"/about"
    },
    {
        name:"headerOnlineProjects",
        url:"/online_project"
    },
    {
        name:"headerEvents",
        url:"/events/past"
    },
    {
        name:"headerPodcast",
        url:"/podcast"
    }
];
