module.exports = {
    header:"aboutUsWhowWeAreHeader",
    subheader:"aboutUsWhowWeAreSubHeader",
    body:"aboutUsWhoWeAreBody",
    img: {
        "desktop":"/img/About/desktop/webp/About_pic2.webp",
        "tablet":"/img/About/desktop/webp/About_pic2.webp",
        "mobile":"/img/About/mobile/webp/About_pic2.webp",
        "alt": "aboutUsPageTopTeamIntroducingImage1Alt"
    }

};
