module.exports = {
    header:"author",
    body:"authorWhoIs",
    img: {
            "desktop":"/img/Main/desktop/webp/Sardarov_main.webp",
            "tablet":"/img/Main/tablet/webp/Sardarov.webp",
            "mobile":"/img/Main/mobile/webp/Sardarov.webp",
            "alt": "mainPageAuthorImageAlt"
        }

};
