module.exports = [
    {
        name: "eventsFutureSlider",
        url: "/events/future"
    },
    {
        name: "eventsPastSlider",
        url: "/events/past"
    }
];
