module.exports = {
    header:"aboutUsOnMapHeader",
    body:"aboutUsOnMapBody",
    img: {
        "desktop":"/img/About/desktop/webp/About_pic3.webp",
        "tablet":"/img/About/tablet/webp/About_pic3.webp",
        "mobile":"/img/About/desktop/webp/About_pic3.webp",
        "alt": "aboutUsPageTopTeamIntroducingImage2Alt"
    }
};
